import React, { useEffect, useRef, useState } from 'react';
import { getImage } from '../router/resources/data';
import './Image.css';
import './Buttons.css';

// Buttons
const StickyButtonPrev: React.FC<{ isVisible: boolean; onClick: () => void; currentNumber: number}> = ({ isVisible, onClick, currentNumber }) => {
  return (
    <button className={`sticky-button-1 ${(isVisible && currentNumber !==0) ? 'visible' : ''}`} onClick={onClick}>
      Previous
    </button>
  );
};
const StickyButtonNext: React.FC<{ isVisible: boolean; onClick: () => void; currentNumber: number}> = ({ isVisible, onClick, currentNumber }) => {
  return (
    <button className={`sticky-button-2 ${(isVisible && currentNumber != 6) ? 'visible' : ''}`} onClick={onClick}>
      Next
    </button>
  );
};

interface Props {
  folder_path: string;
  images: string[];
}

const VisionTransformerButtons: React.FC<Props> = ({ folder_path, images }) => {
  const [filteredImages, setFilteredImages] = useState<string[]>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [currentlyShowing, setCurrentlyShowing] = useState<number>(0);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [image0Visible, setImage0Visible] = useState(true);
  const [image1Visible, setImage1Visible] = useState(false);
  const [image2Visible, setImage2Visible] = useState(false);
  const [image3Visible, setImage3Visible] = useState(false);
  const [image4Visible, setImage4Visible] = useState(false);
  const [image5Visible, setImage5Visible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Fetch images
  useEffect(() => {
    const imgPromises = images.map(image => getImage(folder_path + "..." + image));
    Promise.all(imgPromises)
      .then(results => {
        const imgs = results.filter((result): result is string => result !== undefined);
        setFilteredImages(imgs);

        // Process filtered images if necessary
      })
      .catch(console.error);
    
      
  }, [images, folder_path]);


  // IntersectionObserver setup
  useEffect(() => {

    if (containerRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
            setButtonVisible(entry.isIntersecting);
        },
        
        {
          threshold: 0.5, // Trigger when 50% of the element is visible
        }
      );

      observer.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }
  }, ); // Run after containerRef is initialized

  // Fetch the main image
  useEffect(() => {
    getImage('architectureImages...full').then(url => {
      if (url) {
        setImageUrl(url);
      }
    });
  }, []);

  const showLast = () => {
    if (currentlyShowing === 1){
      setImage1Visible(false);
    }
    else if (currentlyShowing === 2){
      setImage2Visible(false);
    }
    else if (currentlyShowing === 3){
      setImage0Visible(true);
      setImage1Visible(true);
      setImage3Visible(false);
    }
    else if (currentlyShowing === 4){
      setImage2Visible(true);
      setImage3Visible(true);
      setImage4Visible(false);
    }
    else if (currentlyShowing === 5){
      setImage4Visible(true);
      setImage5Visible(false);
    }
    else if (currentlyShowing === 6){
      setImage0Visible(false);
      setImage1Visible(false);
      setImage2Visible(false);
      setImage3Visible(false);
      setImage4Visible(false);

    }    
    setCurrentlyShowing(Math.max(0, currentlyShowing - 1));
  };

  const showNext = () => {
    if (currentlyShowing === 0){
      setImage1Visible(true);
    }
    else if (currentlyShowing === 1){
      setImage2Visible(true);
    }
    else if (currentlyShowing === 2){
      setImage0Visible(false);
      setImage1Visible(false);
      setImage3Visible(true);
    }
    else if (currentlyShowing === 3){
      setImage2Visible(false);
      setImage3Visible(false);
      setImage4Visible(true);
    }
    else if (currentlyShowing === 4){
      setImage4Visible(false);
      setImage5Visible(true);
    }
    else if (currentlyShowing === 5){
      setImage0Visible(true);
      setImage1Visible(true);
      setImage2Visible(true);
      setImage3Visible(true);
      setImage4Visible(false);
    }    
    setCurrentlyShowing(Math.min(6, currentlyShowing + 1));
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {imageUrl && (
        <div>
          <StickyButtonPrev onClick={showLast} isVisible={isButtonVisible} currentNumber={currentlyShowing} />
          <StickyButtonNext onClick={showNext} isVisible={isButtonVisible} currentNumber={currentlyShowing}/>

          {/* Placeholder images instead of SVG */}
          <div ref={containerRef} style={{ width: '1000px', height: '664px', padding: '0px 40px', position: 'sticky' }}>
            <img
              src={filteredImages[0]}
              alt="Placeholder"
              className={`transformer-image ${image0Visible ? 'visible' : ''}`}
              style={{top: 0, left: '50px', width: '150px'}}
            />
            <img
              src={filteredImages[1]}
              alt="Placeholder"
              className={`transformer-image ${image1Visible ? 'visible' : ''}`}
              style={{top: 0, left: '200px', width: '700px'}}
            />
            <img
              src={filteredImages[2]}
              alt="Placeholder"
              className={`transformer-image ${image2Visible ? 'visible' : ''}`}
              style={{top: '120px', left:'200px', width: '600px'}}
            />
            <img
              src={filteredImages[3]}
              alt="Placeholder"
              className={`transformer-image ${image3Visible ? 'visible' : ''}`}
              style={{top: '182px', left: 0, width: '896px'}}
            />
            <img
              src={filteredImages[4]}
              alt="Placeholder"
              className={`transformer-image ${image4Visible ? 'visible' : ''}`}
              style={{top: '152px', left: 0, width: '950px'}}
            />
            <img
              src={filteredImages[5]}
              alt="Placeholder"
              className={`transformer-image ${image5Visible ? 'visible' : ''}`}
              style={{top: '322px', left: 0, width: '950px'}}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VisionTransformerButtons;
/*

*/